import Event from "../Event"

/**
 * Klasa reprezentująca separacje do mamki
 * @extends Event
 */
class SeparationToMommy extends Event {

    constructor(event) {
        //todo obsluzyc wykomentowane
        if (!event.EvData) throw new Error("Brak EvData");
        if (!event.EvData.PiCnt) throw new Error("Brak PiCnt");
        // if (event.EvData.PiCnt && typeof event.EvData.PiCnt !== "number") throw new Error("PiCnt nie jest liczba");
        if (!event.EvData.PiWeight) throw new Error("Brak PiWeight");
        // if (event.EvData.PiWeight && typeof event.EvData.PiWeight !== "number") throw new Error("PiWeight nie jest liczba");
        super(event);
    }
}

export default SeparationToMommy