import Event from "../Event"

/**
 * Klasa reprezentująca kondycje
 * @extends Event
 */
class Condition extends Event {

    constructor(event) {
        if (event.EvData && Object.keys(event.EvData).length !== 0) throw new Error("Kondycja nie powinna zawierać żadnych danych");
        super(event);
    }

}

export default Condition;