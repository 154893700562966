import Device from "../Device";
import {DispenserDriverCommandTypes, DispenserNRFCommandTypes, GatawayCommandTypes} from "../../constans/mqttMessages";
import {checkIfUserIsService} from "../../utils/NewRolesUtils";

/**
 * Klasa reprezentująca Gateway
 * @extends Device
 */
class Gateway extends Device {

    _saveAsArray(configuration, command, isSet, length = 15, isNr = false) {
        let data = configuration[command];
        let number = isNr ? data.nr : data.number;
        if (!this.Settings.Configuration[command]) this.Settings.Configuration[command] = new Array(length).fill(null);
        this.Settings.Configuration[command][number - 1] = {
            ...data,
            isSet,
            SetTime: new Date().getTime()
        };
        delete configuration[command];
    }

    setConfiguration(configuration, isSet = true) {
        if (!this.Settings.Configuration) this.Settings.Configuration = {};
        if (configuration[DispenserDriverCommandTypes.SET_NUTRITION_CURVE]) {
            this._saveAsArray(configuration, DispenserDriverCommandTypes.SET_NUTRITION_CURVE, isSet);
        }
        if (configuration[DispenserDriverCommandTypes.SET_SCHEDULE]) {
            this._saveAsArray(configuration, DispenserDriverCommandTypes.SET_SCHEDULE, isSet);
        }
        if (configuration[DispenserNRFCommandTypes.SET_FEED_EFC_TIME]) {
            this._saveAsArray(configuration, DispenserNRFCommandTypes.SET_FEED_EFC_TIME, isSet, 8, true);
        }
        if (configuration[GatawayCommandTypes.SET_SCHEDULE_OUTPUT]) {
            this._saveAsArray(configuration, GatawayCommandTypes.SET_SCHEDULE_OUTPUT, isSet, 15, true);
        }
        if (configuration[GatawayCommandTypes.SET_SCHEDULE_OUTPUT_MODE]) {
            this._saveAsArray(configuration, GatawayCommandTypes.SET_SCHEDULE_OUTPUT_MODE, isSet, 8, true);
        }
        super.setConfiguration(configuration, isSet);
    }

    getSelectName(index = null, showAlias = true, getLongName = false) {
        if (!checkIfUserIsService()) {
            return `${this.Name}${index !== null ? ` {${index}}` : ""}${showAlias && this.Alias ? ` A: ${this.Alias}` : ""}`
        }
        return `${this.Name}${index !== null ? ` {${index}}` : ""}${showAlias && this.Alias ? ` A: ${this.Alias}` : ""} S/N: ${this.DevID}`;
    }

}

export default Gateway;