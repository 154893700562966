import Device from "../Device";

/**
 * Klasa reprezentująca Bridge
 * @extends Device
 */
class Bridge extends Device {

    /**
     * Metoda ustawia na wskazanym interfejsie, podany protokół
     * @param inter         numer interfejsu z devices.js/Interfaces
     * @param protocol      numer protokolu z devices.js/FrameTypes
     */
    setInterface(inter, protocol) {
        if (!this.Interfaces) {
            this.Interfaces = [];
        }
        this.Interfaces[inter] = protocol;
    }

}

export default Bridge