import Event from "../Event"

/**
 * Klasa reprezentująca separacje
 * @extends Event
 */
class Separation extends Event {

    constructor(event) {
        //todo obsluzyc wykomentowane
        if (!event.EvData) throw new Error("Brak EvData");
        // if (!event.EvData.GrpID) throw new Error("Brak GrpID");
        if (event.EvData.GrpID && typeof event.EvData.GrpID !== "string") throw new Error("GrpID nie jest stringiem");
        // if (!event.EvData.DstID) throw new Error("Brak DstID");
        if (event.EvData.DstID && typeof event.EvData.DstID !== "string") throw new Error("DstID nie jest stringiem");
        if (!event.EvData.PiWeight) throw new Error("Brak PiWeight");
        // if (event.EvData.PiWeight && typeof event.EvData.PiWeight !== "number") throw new Error("PiWeight nie jest liczba");
        if (!event.EvData.PiCnt) throw new Error("Brak PiCnt");
        if (event.EvData.PiCnt && typeof event.EvData.PiCnt !== "number") throw new Error("PiCnt nie jest liczbą");
        super(event);
    }


}

export default Separation;