import Device from "../Device";

/**
 * Klasa reprezentująca paszociąg
 * @extends Device
 */
class ChainFeeding extends Device {

}

export default ChainFeeding;