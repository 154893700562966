import Device from "../Device";
import {DispenserDriverCommandTypes} from "../../constans/mqttMessages";

/**
 * Klasa reprezentująca dozownik WST
 * @extends Device
 */
class DispenserWST extends Device {

    makeDispenserActive(index) {
        this.Dispensers[index].Connected = true;
        this.setDtaModTime();
    }

    makeDispenserInactive(index) {
        this.Dispensers[index].Connected = false;
        this.setDtaModTime();
    }

    getSelectName(index = null, showAlias = true, getLongName = false) {
        let name = super.getSelectName(index, false, getLongName);
        if (showAlias && index !== null) {
            let alias = this.Dispensers[index].Alias;
            if (alias) {
                name += ` A: ${alias}`;
            }
        }
        return name;
    }

    setConfiguration(configuration, isSet = true) {
        if (!this.Settings) this.Settings = {};
        if (!this.Settings.Configuration) this.Settings.Configuration = {};
        if (configuration[DispenserDriverCommandTypes.SET_PARAM_OUTS]) {
            if (!this.Settings.Configuration[DispenserDriverCommandTypes.SET_PARAM_OUTS])
                this.Settings.Configuration[DispenserDriverCommandTypes.SET_PARAM_OUTS] = [];
            for (let key in configuration[DispenserDriverCommandTypes.SET_PARAM_OUTS]) {
                let params = configuration[DispenserDriverCommandTypes.SET_PARAM_OUTS][key];
                this.Settings.Configuration[DispenserDriverCommandTypes.SET_PARAM_OUTS][params.number - 1] = {
                    params,
                    isSet,
                    SetTime: new Date().getTime()
                };
            }
            delete configuration[DispenserDriverCommandTypes.SET_PARAM_OUTS];
        }
        super.setConfiguration(configuration, isSet);
    }

}

export default DispenserWST;