import Device from "../Device";

/**
 * Klasa reprezentująca Work dla bridga
 * @extends Device
 */
export class BridgeWork extends Device{

}

export default BridgeWork;