import Device from "../Device";
import {get} from "lodash";

/**
 * Klasa reprezentująca sterownik klimatu SK3
 * @extends Device
 */
class ClimateSK3 extends Device {

    getMatsSize() {
        return get(this, "Settings.MatsSize", 8);
    }

    setMatSize(_size=0) {
        if(!this.Settings) this.Settings = {};
        this.Settings.MatsSize = _size;
    }

}

export default ClimateSK3;
