import Event from "../Event"

/**
 * Klasa reprezentująca sczepienie
 * @extends Event
 */
class Grafting extends Event {

    constructor(event) {
        if (!event.EvData) throw new Error("Brak EvData");
        if (!event.EvData.Medicine) throw new Error("Brak Medicine");
        if (event.EvData.Medicine && typeof event.EvData.Medicine !== "string") throw new Error("Medicine nie jest stringiem");
        if (!event.EvData.Reasn) throw new Error("Brak Reasn");
        if (event.EvData.Reasn && typeof event.EvData.Reasn !== "string") throw new Error("Reasn nie jest stringiem");
        if (!event.EvData.Weight) throw new Error("Brak Weight");
        if (event.EvData.Weight && typeof event.EvData.Weight !== "number") throw new Error("Weight nie jest liczbą");
        super(event);
    }

}

export default Grafting;