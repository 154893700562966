import Device from "../Device";

/**
 * Klasa reprezentująca Broadcast dla bridga
 * @extends Device
 */
export class BridgeBroadcast extends Device{

}

export default BridgeBroadcast;