import Device from "../Device";
import {get} from "lodash";

/**
 * Klasa reprezentująca dozownik NRF
 * @extends Device
 */
class DispenserNRF extends Device {

    setTemperatureSensor(_enabled = false) {
        if (!this.Settings) this.Settings = {};
        if (!this.Settings.Temperature) this.Settings.Temperature = {};
        this.Settings.Temperature.Sensor = !!_enabled;
    }

    setTemperatureIndividual(_individual = true) {
        if (!this.Settings) this.Settings = {};
        if (!this.Settings.Temperature) this.Settings.Temperature = {};
        this.Settings.Temperature.Individual = !!_individual;
    }

    setWater(_enabled = false) {
        if (!this.Settings) this.Settings = {};
        if (!this.Settings.Water) this.Settings.Water = {};
        this.Settings.Water.Enabled = !!_enabled;
        if (!_enabled && this.Settings.Water.FlowMeter) {
            //jesli usunie sie wode to przeplywomierz tez
            this.Settings.Water.FlowMeter = false;
        }
    }

    setFlowMeter(_enabled = false) {
        if (!this.Settings) this.Settings = {};
        if (!this.Settings.Water) this.Settings.Water = {};
        this.Settings.Water.FlowMeter = this.Settings.Water.Enabled ? !!_enabled : false;
    }

    hasTemperatureSensor() {
        return !!get(this, "Settings.Temperature.Sensor", false);
    }

    hasWater() {
        return !!get(this, "Settings.Water.Enabled", false);
    }

    hasFlowMeter() {
        return !!get(this, "Settings.Water.FlowMeter", false);
    }

    isIndividualTemperature() {
        return !!get(this, "Settings.Temperature.Individual", false);
    }
}

export default DispenserNRF;
