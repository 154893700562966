import Device from "../Device";

/**
 * Klasa reprezentująca sterownik klimatu WST
 * @extends Device
 */
class Climate extends Device {

}

export default Climate;