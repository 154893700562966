import Device from "../Device";

/**
 * Klasa reprezentująca czasy dla dozowników
 * @extends Device
 */
export class DispenserTime extends Device{

}

export default DispenserTime;