import Event from "../Event"

/**
 * Klasa reprezentująca poród
 * @extends Event
 */
class Parturition extends Event {

    constructor(event) {
        if (!event.EvData) throw new Error("Brak EvData");
        if (event.EvData.DeadCnt === undefined) throw new Error("Brak DeadCnt");
        // if (typeof event.EvData.DeadCnt !== "number") throw new Error("DeadCnt musi być liczbą");
        if (event.EvData.HealthyCnt === undefined) throw new Error("Brak HealthyCnt");
        // if (typeof event.EvData.HealthyCnt !== "number") throw new Error("HealthyCnt musi być liczbą");
        if (event.EvData.MummyCnt === undefined) throw new Error("Brak MummyCnt");
        // if (typeof event.EvData.MummyCnt !== "number") throw new Error("MummyCnt musi być liczbą");
        if (event.EvData.Weight === undefined) throw new Error("Brak Weight");
        if (typeof event.EvData.Weight !== "number") throw new Error("Weight musi być liczbą");
        super(event);
    }
}

export default Parturition;