import Event from "../Event"

/**
 * Klasa reprezentująca ważenie
 * @extends Event
 */
class Weighting extends Event {

    constructor(event) {
        if (!event.EvData) throw new Error("Brak EvData");
        if (!event.EvData.Weight) throw new Error("Brak Weight");
        if (typeof event.EvData.Weight !== "number") throw new Error("Weight nie jest liczbą");
        if (!event.EvData.Reasn) throw new Error("Brak Reasn");
        if (typeof event.EvData.Reasn !== "string") throw new Error("Reasn nie jest stringiem");
        super(event);
    }


}

export default Weighting;