import Event from "../Event"

/**
 * Klasa reprezentująca event mamki
 * @extends Event
 */
class Mommy extends Event {

    constructor(event) {
        //todo obsluzyc wykomentowane
        if (!event.EvData) throw new Error("Brak EvData");
        if (!event.EvData.PiCnt) throw new Error("Brak PiCnt");
        // if (event.EvData.PiCnt && typeof event.EvData.PiCnt !== "number") throw new Error("PiCnt nie jest liczba");
        super(event);
    }

}

export default Mommy;