import Event from "../Event"

/**
 * Klasa reprezentująca updatek
 * @extends Event
 */
class Fall extends Event {

    constructor(event) {
        if (!event.EvData) throw new Error("Brak EvData");
        if (!event.EvData.PlcmntID) throw new Error("Brak PlcmntID");
        if (event.EvData.PlcmntID && typeof event.EvData.PlcmntID !== "string") throw new Error("PlcmntID nie jest stringiem");
        if (!event.EvData.Reasn) throw new Error("Brak Reasn");
        if (event.EvData.Reasn && typeof event.EvData.Reasn !== "string") throw new Error("Reasn nie jest stringiem");
        if (!event.EvData.Weight) throw new Error("Brak Weight");
        if (event.EvData.Weight && typeof event.EvData.Weight !== "number") throw new Error("Weight nie jest liczbą");
        if (!event.EvData.AnmCnt) throw new Error("Brak AnmCnt");
        if (event.EvData.AnmCnt && typeof event.EvData.AnmCnt !== "number") throw new Error("AnmCnt nie jest liczbą");
        super(event);
    }
}

export default Fall;