import Device from "../Device";

/**
 * Klasa reprezentująca antene RFID
 * @extends Device
 */
class AntennaRFID extends Device {

}

export default AntennaRFID;