import Event from "../Event"

/**
 * Klasa reprezentująca ilość aktywnych sutków
 * @extends Event
 */
class ActiveNipples extends Event {

    constructor(event) {
        if (!event.EvData) throw new Error("Brak EvData");
        if (!event.EvData.Nipples) throw new Error("Brak Nipples");
        if (event.EvData.Nipples && typeof event.EvData.Nipples !== "number") throw new Error("Nipples nie jest liczbą");
        super(event);
    }
}

export default ActiveNipples;