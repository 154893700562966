import Event from "../Event"

/**
 * Klasa reprezentująca sprzedaż
 * @extends Event
 */
class Sell extends Event {

    constructor(event) {
        if (!event.EvData) throw new Error("Brak EvData");
        if (!event.EvData.Buyer) throw new Error("Brak Buyer");
        if (event.EvData.Buyer && typeof event.EvData.Buyer !== "string") throw new Error("Buyer nie jest stringiem");
        if (!event.EvData.Weight) throw new Error("Brak Weight");
        if (event.EvData.Weight && typeof event.EvData.Weight !== "number") throw new Error("Weight nie jest liczbą");
        if (!event.EvData.PlcmntID) throw new Error("Brak PlcmntID");
        if (event.EvData.PlcmntID && typeof event.EvData.PlcmntID !== "string") throw new Error("PlcmntID nie jest stringiem");
        if (!event.EvData.AnmCnt) throw new Error("Brak AnmCnt");
        if (event.EvData.AnmCnt && typeof event.EvData.AnmCnt !== "number") throw new Error("AnmCnt nie jest liczbą");
        if (!event.EvData.Hcw) throw new Error("Brak HCW");
        if (event.EvData.Hcw && typeof event.EvData.Hcw !== "number") throw new Error("Hcw nie jest liczbą");
        super(event);
    }
}

export default Sell;