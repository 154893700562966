import Event from "../Event"

/**
 * Klasa reprezentująca sczepienie
 * @extends Event
 */
class PigletsTreatment extends Event {

    constructor(event) {
        if (!event.EvData) throw new Error("Brak EvData");
        if (!event.EvData.Medicine) throw new Error("Brak Medicine");
        if (event.EvData.Medicine && typeof event.EvData.Medicine !== "string") throw new Error("Medicine nie jest stringiem");
        if (!event.EvData.Reasn) throw new Error("Brak Reasn");
        if (event.EvData.Reasn && typeof event.EvData.Reasn !== "string") throw new Error("Reasn nie jest stringiem");
        if (!event.EvData.OperID) throw new Error("Brak OperID");
        if (event.EvData.OperID && typeof event.EvData.OperID !== "string") throw new Error("OperID nie jest stringiem");
        if (!event.EvData.PiCnt) throw new Error("Brak Piglets");
        if (event.EvData.PiCnt && typeof event.EvData.PiCnt !== "number") throw new Error("PiCnt nie jest liczbą");
        if (!event.EvData.TreatmentType) throw new Error("Brak TreatmentType");
        if (event.EvData.TreatmentType && typeof event.EvData.TreatmentType !== "number") throw new Error("TreatmentType nie jest liczbą");
        if (!event.EvData.PiWeight) throw new Error("Brak Weight");
        if (event.EvData.PiWeight && typeof event.EvData.PiWeight !== "number") throw new Error("PiWeight nie jest liczbą");
        super(event);
    }

}

export default PigletsTreatment;