import Device from "../Device";
import {ScaleHubCommandTypes} from "../../constans/mqttMessages";

/**
 * Klasa reprezentująca panel wagowy
 * @extends Device
 */
class Scale extends Device {

    getSelectName(index = null, showAlias = true, getLongName = false) {
        let name = super.getSelectName(index, false, getLongName);
        if (showAlias && index !== null) {
            try {
                let alias = this.Siloses[index].Alias;
                if (alias) name += ` A: ${alias}`;
            } catch (e) {
                console.error(e);
            }
        }
        return name;
    }

    _saveTareOrScale(configuration, command, isSet) {
        let data = configuration[command];
        if (!this.Settings.Configuration[command]) this.Settings.Configuration[command] = new Array(8).fill(null);
        this.Settings.Configuration[command][data.number - 1] = {
            ...data,
            isSet,
            SetTime: new Date().getTime()
        };
        delete configuration[command];
    }

    setConfiguration(configuration, isSet = true) {
        if (!this.Settings) this.Settings = {};
        if (!this.Settings.Configuration) this.Settings.Configuration = {};
        if (configuration[ScaleHubCommandTypes.SET_TARE]) {
            this._saveTareOrScale(configuration, ScaleHubCommandTypes.SET_TARE, isSet);
        }
        if (configuration[ScaleHubCommandTypes.SET_SCALE]) {
            this._saveTareOrScale(configuration, ScaleHubCommandTypes.SET_SCALE, isSet);
        }
        super.setConfiguration(configuration, isSet);
    }

}

export default Scale;