import Event from "../Event"

/**
 * Klasa reprezentująca inseminacje
 * @extends Event
 */
class Insemination extends Event {

    constructor(event) {
        if (!event.EvData) throw new Error("Brak EvData");
        if (!event.EvData.BoarID) throw new Error("Brak BoarID");
        if (typeof event.EvData.BoarID !== "string") throw new Error("BoarID nie jest stringiem");
        super(event);
    }

}

export default Insemination;