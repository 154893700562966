import Event from "../Event"

/**
 * Klasa reprezentująca selekcje
 * @extends Event
 */
class Selection extends Event {

    constructor(event) {
        if (!event.EvData) throw new Error("Brak EvData");
        if (!event.EvData.PlcmntID) throw new Error("Brak PlcmntID");
        if (event.EvData.PlcmntID && typeof event.EvData.PlcmntID !== "string") throw new Error("PlcmntID nie jest stringiem");
        if (!event.EvData.Reasn) throw new Error("Brak Reasn");
        if (event.EvData.Reasn && typeof event.EvData.Reasn !== "string") throw new Error("Reasn nie jest stringiem");
        super(event);
    }

}

export default Selection;