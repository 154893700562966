import Event from "../Event"

/**
 * Klasa reprezentująca rozpisanie leczenie
 * @extends Event
 */
class Treatment extends Event {

    constructor(event) {
        if (!event.EvData) throw new Error("Brak EvData");
        if (!event.EvData.Treatment) throw new Error("Brak Treatment");
        if (event.EvData.Treatment && !Array.isArray(event.EvData.Treatment)) throw new Error("Treatment nie jest tablicą");
        super(event);
    }

    /**
     * Usuwa z listy wybrany dzień
     * @param date {number}     timestamp dnia, który ma usunąć
     * @param index {number}    numer indeksu z tablicy EvData.Treatment, aby zabezpieczyć się przed usunięciem niepożądanego rekordu
     */
    removeDate(date, index) {
        let treatment = this.EvData.Treatment[index];
        treatment.Dates = treatment.Dates.filter(d => d !== date);
        if (treatment.Dates.length === 0) {
            this.EvData.Treatment.splice(index, 1);
        }
        if (this.EvData.Treatment.length === 0) {
            this.deleteEvent();
        }
        this.setDtaModTime();
    }

}

export default Treatment;