import Event from "../Event"

/**
 * Klasa reprezentująca przeniesienie
 * @extends Event
 */
class Transfer extends Event {

    constructor(event) {
        if (!event.EvData) throw new Error("Brak EvData");
        if (!event.EvData.DstID) throw new Error("Brak DstID");
        if (typeof event.EvData.DstID !== "string") throw new Error("DstID nie jest stringiem"); //zamienic na sprawdzanie obiektu
        if (!event.EvData.SrcID && !event.EvData.SrdID) throw new Error("Brak SrcID");
        if (event.EvData.SrcID && typeof event.EvData.SrcID !== "string") throw new Error("SrcID nie jest stringiem");
        if (event.EvData.SrdID && typeof event.EvData.SrdID !== "string") throw new Error("SrdID nie jest stringiem");
        super(event);
    }

}

export default Transfer;