import Event from "../Event"

/**
 * Klasa reprezentująca ilość cykli oraz date ostatniego odsadu
 * @extends Event
 */
class SowCycles extends Event {

    constructor(event) {
        if (!event.OperID) throw new Error("Brak OperId");
        if (!event.EvData) throw new Error("Brak EvData");
        if (!event.EvData.Cycles) throw new Error("Brak Cycles");
        if (event.EvData.Cycles && typeof event.EvData.Cycles !== "number") throw new Error("Cycles nie jest liczbą");
        if (!event.EvData.LastSeparation) throw new Error("Brak lastSeparation");
        if (event.EvData.LastSeparation && typeof event.EvData.LastSeparation !== "number") throw new Error("lastSeparation nie jest liczbą");
        super(event);
    }

}

export default SowCycles;