import Device from "../Device";

/**
 * Klasa reprezentująca Conf dla bridga
 * @extends Device
 */
export class BridgeConf extends Device{

}

export default BridgeConf;