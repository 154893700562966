import Device from "../Device";
import moment from "moment";

/**
 * Klasa reprezentująca klatkę separacyjną
 * @extends Device
 */
class Cage extends Device {

    /**
     * Dodawany jest nowy rzut do klatki
     * @param startTime {number}     timestamp rozpoczęcia nowego rzutu
     * @param weight {number}
     * @param amount {number}
     */
    startInsertion(startTime, weight, amount) {
        if (!this.Settings) this.Settings = {};
        if (!this.Settings.Insertions) this.Settings.Insertions = [];
        this.Settings.Insertions = this.Settings.Insertions.filter(item => item.StartTime);
        this.Settings.Insertions.push({
            StartTime: startTime,
            Weight: weight,
            Amount: amount
        });
        this.setDtaModTime();
    }

    /**
     * Zakańczany jest pierwszy znaleziony rzut
     * @param insertionStart
     * @param endTime
     */
    endInsertion(insertionStart, endTime = moment.utc().startOf("day").toDate().getTime()) {
        let insertion = this.Settings.Insertions.find(item => item.StartTime === insertionStart && !item.Stop);
        if (insertion) {
            insertion.EndTime = endTime;
            this.setDtaModTime();
        }
    }

    endLastInsertion(endTime) {
        for (let insertion of this.Settings.Insertions) {
            if (!insertion.EndTime) {
                insertion.EndTime = endTime;
                break;
            }
        }
    }

    setMarker(markerNumber = 1, {fluidAmount = 0, fluidColor = '#FFFFFF'} = {}) {
        if (!this.Settings) this.Settings = {};
        if (!this.Settings.Marker) this.Settings.Marker = [];
        this.Settings.Marker[markerNumber - 1] = {
            Color: fluidColor,
            Amount: fluidAmount
        };
        this.setDtaModTime();
    }

    endExpedition(time = new Date().getTime()) {
        if (!this.Settings) this.Settings = {};
        if (!this.Settings.Expeditions) this.Settings.Expeditions = [];
        if (this.Settings.Expeditions.length > 0) {
            let lastExpedition = this.Settings.Expeditions[this.Settings.Expeditions.length - 1];
            if (!lastExpedition.EndDate) {
                lastExpedition.EndDate = time;
            }
        }
    }

    addExpedition(StartDate, AnimalAmount, MinWeight, MaxWeight, ExpeditionWorktype, IsNewExpedition) {
        if (!this.Settings) this.Settings = {};
        if (!this.Settings.Expeditions) this.Settings.Expeditions = [];
        this.endExpedition();
        this.Settings.Expeditions.push({
            StartDate,
            AnimalAmount,
            MinWeight,
            MaxWeight,
            ExpeditionWorktype,
            IsNewExpedition
        });
        this.Settings.Expeditions.sort((a, b) => a.StartDate - b.StartDate);
        this.setDtaModTime();
    }

    getLastExpedition() {
        try {
            return this.Settings.Expeditions[this.Settings.Expeditions.length - 1];
        } catch (e) {
            return null;
        }
    }

    /**
     * Metoda zwraca tablice z listą ekspedycji
     * @return {[{startDate: number, endDate: number|null}]|*[]}
     */
    getExpeditionList() {
        try {
            let array = [];
            let lastObject = null;
            for (let expedition of this.Settings.Expeditions) {
                console.log(lastObject);
                // sprawdzanie czy aktualny obiekt rozpaczal nowa ekspedycje
                // jezeli rozpoczal to odejmuje minute od czasu startu i zapisuje obiekt
                if (lastObject) {
                    if (expedition.IsNewExpedition) {
                        if (!lastObject.endDate) {
                            lastObject.endDate = moment(expedition.StartDate).subtract(1, "minute").toDate().getTime();
                        }
                        array.push(lastObject);
                        lastObject = null;
                    } else if (expedition.EndDate) {
                        lastObject.endDate = expedition.EndDate
                    } else {
                        lastObject.endDate = null;
                    }
                }
                if (!lastObject) {
                    lastObject = {
                        startDate: expedition.StartDate,
                        endDate: expedition.EndDate || null
                    }
                }
            }
            array.push(lastObject);
            return array.filter(o => !!o);
        } catch (e) {
            return [];
        }
    }
}

export default Cage;
