import Event from "../Event"

/**
 * Klasa reprezentująca badanie USG
 * @extends Event
 */
class USG extends Event {

    constructor(event) {
        if (!event.EvData) throw new Error("Brak EvData");
        if (event.EvData.Pregnant === undefined) throw new Error("Brak Pregnant");
        super(event);
    }

}

export default USG;
