import Device from "../Device";

/**
 * Klasa reprezentująca dozownik NRF multi
 * @extends Device
 */
class DispenserNRFMulti extends Device {

}

export default DispenserNRFMulti;