import Event from "../Event"

/**
 * Klasa reprezentująca brak ciąży
 * @extends Event
 */
class NoPregnancy extends Event {

    constructor(event) {
        if (!event.EvData) throw new Error("Brak EvData");
        if (!event.EvData.Reason) throw new Error("Brak Reason");
        super(event);
    }

}

export default NoPregnancy