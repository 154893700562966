import Event from "../Event"

/**
 * Klasa reprezentująca ruję
 * @extends Event
 */
class Heat extends Event {

    constructor(event) {
        if (!event.EvData) throw new Error("Brak EvData");
        super(event);
    }
}

export default Heat;