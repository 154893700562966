import Event from "../Event"

/**
 * Klasa reprezentujaca upadek prosiąt
 * @extends Event
 */
class FallPiglets extends Event {

    constructor(event) {
        //todo obsluzyc wykomentowane
        if (!event.EvData) throw new Error("Brak EvData");
        if (!event.EvData.Piglets) throw new Error("Brak Piglets");
        // if (event.EvData.Piglets && typeof event.EvData.Piglets !== "number") throw new Error("Piglets nie jest liczbą");
        if (!event.EvData.PlcmntID) throw new Error("Brak PlcmntID");
        if (event.EvData.PlcmntID && typeof event.EvData.PlcmntID !== "string") throw new Error("PlcmntID nie jest stringiem");
        if (!event.EvData.Reasn) throw new Error("Brak Reasn");
        if (event.EvData.Reasn && typeof event.EvData.Reasn !== "string") throw new Error("Reasn nie jest stringiem");
        if (!event.EvData.Weight) throw new Error("Brak Weight");
        // if (event.EvData.Weight && typeof event.EvData.Weight !== "number") throw new Error("Weight nie jest liczbą");
        super(event);
    }
}

export default FallPiglets;