import Device from "../Device";
import moment from "moment";
import {checkIfUserIsService} from "../../utils/NewRolesUtils";
import animalsDB from "../../database/animalsDB";

/**
 * Klasa reprezentująca klatkę warchlaków
 * @extends Device
 */
class PigletScale extends Device {

    startInsertion(time, weight, age, plannedSellWeight) {
        if (!this.Settings.Insertions) this.Settings.Insertions = [];
        if (this.hasUnfinishedInsertion()) throw new Error("Found not ended insertion");
        let birthTime = moment.utc(time).startOf("day").subtract(age, "days");
        this.Settings.Insertions.push({
            StartTime: time,
            Weight: weight,
            BirthTime: birthTime.toDate().getTime(),
            PlannedSellWeight: plannedSellWeight
        });
        this.Settings.Insertions.sort((a, b) => a.StartTime - b.StartTime);
        this.setDtaModTime();
    }

    endInsertion(startTime, endTime) {
        for (let insertion of this.Settings.Insertions) {
            if (insertion.StartTime === startTime) {
                insertion.EndTime = endTime;
                break;
            }
        }
        this.setDtaModTime();
    }

    endStartedInsertion(endTime) {
        for (let insertion of this.Settings.Insertions) {
            if (!insertion.EndTime) {
                insertion.EndTime = endTime;
                break;
            }
        }
    }

    hasUnfinishedInsertion() {
        return !!this.Settings.Insertions.find(item => !item.EndTime);
    }

    getSelectName(index = null, showAlias = true, getLongName = false) {
        let locations = this.getLocation(index);
        if (!checkIfUserIsService()) {
            return `${this.Name}}${showAlias && this.Alias ? ` A: ${this.Alias}` : ""} (${locations.map(l => {
                if (getLongName) {
                    let locs = animalsDB.getAnimalLocationsByPlcmntID(l.FarmID || l.BgID || l.SID || l.CID || l.BID);
                    return locs.map(loc => loc.name).filter(item => item).join(", ");
                }
                return l ? l.CName || l.BName || l.SName || l.FarmName || l.BoxesName : undefined;
            }).filter(item => item).join(", ")})`
        }
        return `${this.Name}${showAlias && this.Alias ? ` A: ${this.Alias}` : ""} (${locations.map(l => {
            if (getLongName) {
                let locs = animalsDB.getAnimalLocationsByPlcmntID(l.FarmID || l.BgID || l.SID || l.CID || l.BID);
                return locs.map(loc => loc.name).filter(item => item).join(", ");
            }
            return l ? l.CName || l.BName || l.SName || l.FarmName || l.BoxesName : undefined;
        }).filter(item => item).join(", ")}) S/N: ${this.DevID}`;
    }

}

export default PigletScale;